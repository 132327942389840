import React from "react"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const CookieConsentComp = ({ intl }) => (

    <CookieConsent
    location="bottom"
    buttonText={intl.formatMessage({ id: "cookiesConsentBtn" })}
    buttonClasses="btn btn-primary"
    cookieName="myAwesomeCookieName2"
    style={{ background: "black" }}
    buttonStyle={{ background:"rgb(40, 120, 250)", color: "white", fontSize: "14px", fontWeight: "bold" }}
    expires={150}>
      <FormattedMessage id="cookiesConsentTxt"/>
  </CookieConsent>
  // overlay
 
 )
 
 export default injectIntl(CookieConsentComp)