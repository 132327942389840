import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../styles/components/button.scss'

const STYLES = ['btn--primary', 'btn--outlineGray2', 'btn--primaryWhite2','btn--primaryPurple3','btn--primaryPurple5' ,'btn--outlinePurple3', 'btn--primaryWhite','btn--stepPurple', 'btn--stepGray','btn--outlinePurple2', 'btn--primaryPurple4', 'btn--primaryPurple2','btn--outlineGreen', 'btn--primaryGreen', 'btn--primaryLightBlue', 'btn--primaryLightPurple', 'btn--primaryPurple', 'btn--outlineGray', 'btn--outlineWhite', 'btn--outline', 'btn--outlinePurpleBlue', 'btn--outlineBlue', 'btn--outlinePurple', 'btn--outlineBlack', 'btn--outlinePurpleCircle', 'btn--primaryBlack', 'btn--navSigninLight', 'btn--navSigninDark', 'btn--test', 'btn--navItemLight', 'btn--navItemDark'];
 
const SIZES = ['btn--medium', 'btn--medium2', 'btn--txt5', 'btn--circle','btn--square', 'btn--txt3', 'btn--large4', 'btn--txt4', 'btn--huge2' ,'btn--large3 ', 'btn--txt2','btn--txt','btn--large2', 'btn--small' ,'btn--large', 'btn--huge', 'btn--huge3','btn--largeLight', 'btn--signinMedium'];

const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  href
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize)
    ? buttonSize
    : SIZES[0];


  return (
    <a href={href} className='btn-mobile'>
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </a>
  );
}

export default Button