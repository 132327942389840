module.exports = {
  siteTitle: 'Software Factory',
  siteTitleAlt: 'Software Factory', // This allows an alternative site title for SEO schema.
  publisher: 'Publisher named Sonixc', // Organization name used for SEO schema
  yearOfCreation: '2009',
  siteEmail:'info@softwarefactory.io',
  siteDescription:
    'A starter template to build amazing static websites with Gatsby, Contentful and Netlify',
  siteUrl: 'https://softwarefactory.netlify.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  resourcesInitialShowCount:10,
  resourcesPerHomePage: 7, // Number of resources shown on the 1st page of of the index.js template (home page)
  resourcesPerPage: 6, // Number of resources shown on paginated pages
  author: 'Sonixc User', // Author for RSS author segment and SEO schema
  authorUrl: 'https://softwarefactory.netlify.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@twitter', // Change for Twitter Cards
  shortTitle: 'Sonixc App', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 Sonixc User', // Copyright string for the RSS feed
  menuTitle: ['home','contact','web'], // Copyright string for the RSS feed
  assetTypes: ['All','Blog','White Paper','News','Brochure', 'Product Sheet'], // Copyright string for the RSS feed
  resourceType_Blog:'Blog',
  resourceType_Whitepaper:'White Paper',
  resourceType_News:'News',
  resourceType_Brochure:'Brochure',
  resourceType_ProductSheet:'Product Sheet',
  // resourceCenter:'/resource-center/', 
  // navigationURL_home:'/index/',
  navigationURL_home:'/',
  navigationURL_home_en:'/en/',
  navigationURL_home_de:'/de/',
  navigationURL_plateform:'/platform/',
  navigationURL_apps:'/apps/',
  navigationURL_solutions_chatbot:'/solution/chatbot/',
  navigationURL_solutions_ai:'/solution/ai-driver-digital-twin/',
  navigationURL_solutions_bim:'/solution/bim/',
  navigationURL_services:'/services/customer-experience/',
  navigationURL_resourceCenter:'/resource-center/',
  navigationURL_contactUs:'/contact/sales/',
  navigationURL_contactSales:'/contact/sales/',
  navigationURL_aboutUs:'/about/',
  navigationURL_blog:'/blog/',
  navigationURL_cookie:'/legal/cookie-policy/',
  navigationURL_pricing:'/pricing/',
  navigationURL_privacy:'/legal/imprint/',
  navigationURL_sitemap:'/sitemap/',
  navigationURL_support:'/support/',
  navigationURL_product:'/product/',
  navigationURL_feature: '/feature/',
  navigationURL_mobileapp:'/workplace-mobile-app/',
  navigationURL_register:'/register/',
  navigationURL_login:'/login/',
  navigationURL_solution_space_utilization:'/space-utilization/',
  navigationURL_solution_deskbooking:'/desk-booking/',
  navigationURL_solution_roombooking:'/room-booking/',
  navigationURL_solution_hybridwork:'/hybrid-work/',
  navigationURL_solution_occupancy:'/occupancy-management/',
  navigationURL_solution_automation:'/workplace-automation/',
  navigationURL_solution_space:'/space-management/',
  navigationURL_solution_workexperience:'/work-experience/',
  navigationURL_sustainability:'/sustainability/',
  navigationURL_emailSupport:'/contact/email/',
  navigationURL_smartAssistant: '/smart-assistant/',
  navigationURL_legal:'/legal/',
  navigationURL_legal_imprint:'/legal/imprint/',
  navigationURL_legal_cookie_policy:'/legal/cookie-policy/',
  navigationURL_legal_data_protection:'/legal/data-protection/',
  navigationURL_legal_subscription_conditions:'/legal/subscription-conditions/',
  navigationURL_legal_license_terms:'/legal/license-terms/',
  navigationURL_legal_terms_and_conditions:'/legal/terms-and-conditions/',
  navigationURL_legal_requirements:'/legal/requirements/',
  navigationURL_legal_compliance:'/legal/compliance/',
  navigationURL_docs:'/docs/',
  navigationURL_docs_workplace:'/docs/workplace/',
  //language keywords
  local_DE:"de",
  local_EN:"en",
}
